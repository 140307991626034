.about {
  color: #4d4c4a;
  text-decoration: none;
}

.about:hover {
  color: #9b998a;
  text-decoration: none;
  transition-duration: 0.8s;
}

.name {
  width: 250px;
}

.centered {
  opacity: 0;
  color: #fff;
  position: absolute;
  top: 45%;
  left: 0;
  width: 100%;
}

.centered2 {
  opacity: 0;
  color: #fff;
  position: absolute;
  top: 45%;
  width: 100%;
}

.mainImage {
  position: relative;
}

.mainImage:hover .centered,
.mainImage:hover .centered2 {
  opacity: 0.8;
  transition-duration: 0.8s;
  cursor: pointer;
}

.mainImage1,
.mainImage2,
.mainImage3,
.mainImage4,
.mainImage5 {
  width: 90%;
}

.mainImage:hover .mainImage1,
.mainImage:hover .mainImage2,
.mainImage:hover .mainImage3,
.mainImage:hover .mainImage4,
.mainImage:hover .mainImage5 {
  transform: scale(0.9);
  transition-duration: 0.8s;
  opacity: 0.5;
  cursor: pointer;
}

@media (min-width: 1025px) {
  .mainImage1 {
    width: 92%;
  }

  .mainImage2 {
    width: 114%;
  }

  .mainImage3 {
    width: 76%;
  }

  .mainImage4 {
    width: 100%;
  }

  .mainImage5 {
    width: 89%;
  }
}

.imageBorder {
  margin: 4px 0 0 4px;
  box-shadow: 0 0 0 9px #000;
}
