@media (min-width: 1025px) {
  .galleryImage {
    height: 20rem;
  }
}

@media (max-width: 1025px) {
  .galleryImage {
    width: 90%;
  }
}

.galleryLightImage {
  width: 90%;
}

.galleryImage {
  margin: 4px 0 0 4px;
  box-shadow: 0 0 0 9px #000;
}
