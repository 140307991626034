.instagram {
  width: 2rem;
}

.instagram:hover {
  opacity: 0.7;
  cursor: pointer;
}

.fiver {
  width: 4rem;
}

.fiver:hover {
  opacity: 0.7;
  cursor: pointer;
}
